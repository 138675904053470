import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation } from 'react-router-dom';

import { NavigationPromptContext } from './context';
import ConfirmNavigationDialog from './ConfirmNavigationDialog';

const NavigationPrompt: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  const [blockNavigation, setBlockNavigation] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const onConfirmCallbackRef = useRef<() => void>();
  const blocker = useBlocker(blockNavigation);

  useEffect(() => {
    clearNavigationBlock();
  }, [pathname]);

  const raiseNavigationBlock = () => {
    setBlockNavigation(true);
  };

  const clearNavigationBlock = () => {
    setBlockNavigation(false);
    setShowPrompt(false);
    onConfirmCallbackRef.current = undefined;
  };

  const handleNavigationPrompt = useCallback(
    (onConfirm: () => void) => {
      if (blockNavigation) {
        setShowPrompt(true);
        onConfirmCallbackRef.current = onConfirm;
      } else {
        onConfirm();
      }
    },
    [blockNavigation]
  );

  const confirmNavigation = () => {
    if (onConfirmCallbackRef.current) {
      onConfirmCallbackRef.current();
    } else {
      blocker.proceed && blocker.proceed();
    }
    clearNavigationBlock();
  };

  const cancelNavigation = () => {
    setShowPrompt(false);
    onConfirmCallbackRef.current = undefined;
    blocker.reset && blocker.reset();
  };

  return (
    <NavigationPromptContext.Provider
      value={{
        blocking: blocker.state === 'blocked',
        raiseNavigationBlock,
        clearNavigationBlock,
        handleNavigationPrompt,
      }}
    >
      <ConfirmNavigationDialog
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        isOpen={blocker.state === 'blocked' || showPrompt}
      />
      {children}
    </NavigationPromptContext.Provider>
  );
};

export default NavigationPrompt;
